import { SELECTED_TENANT } from "../constants/tenantConstants";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  HOME,
  IMPORTANT,
  RESOLVED,
  ARCHIVED,
  FLAGGED,
  SNOOZED,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        user: action.payload.user,
        tenants: action.payload.tenants,
      };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const selectedTenantReducer = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_TENANT:
      return { id: action.payload };
    default:
      return state;
  }
};
export const drawerReducer = (state = { menuItem: "Open" }, action) => {
  switch (action.type) {
    case HOME:
      return { menuItem: "Open" };
    case IMPORTANT:
      return { menuItem: IMPORTANT };
    case SNOOZED:
      return { menuItem: SNOOZED };
    case FLAGGED:
      return { menuItem: FLAGGED };
    case ARCHIVED:
      return { menuItem: ARCHIVED };
    case RESOLVED:
      return { menuItem: RESOLVED };
    default:
      return state;
  }
};
