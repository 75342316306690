import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Chip,
  Collapse,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { typography } from "../../../shared/theme-constants";
import { useSelector } from "react-redux";
import EastIcon from '@mui/icons-material/East';

const DEFAULT_TIMEZONE = "Etc/UTC";

const RenderRows = (rows) => {
  const { row, event_type } = rows;
  const [open, setOpen] = useState(false);
  // Get the tenant configs
  const tenantConfigs = useSelector((state) => state.tenantConfigs.configs);
  // Get the timezone from the tenant configs
  let timezone = tenantConfigs?.timezone?.name ? tenantConfigs.timezone.name : DEFAULT_TIMEZONE;
  if (event_type === 'error') {
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { border: "none", ":hover": { cursor: "pointer" } } }} onClick={() => setOpen(!open)}>
          <TableCell sx={{ border: "none" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="center" sx={{ border: "none", fontWeight: typography.font_weight.mediumx }}>
            {moment.tz(row.start_time, DEFAULT_TIMEZONE).tz(timezone).format("YYYY-MM-DD hh:mm A z")}
          </TableCell>
          <TableCell align="center" sx={{ border: "none", padding: 0 }}>
            {row.status_code ? row.status_code : "-"}
          </TableCell>
          <TableCell align="left" sx={{ border: "none", padding: 0 }}>
            {row?.payload ? <Chip label={row.payload} sx={(theme) => ({ maxWidth: 670 })} /> : "-"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ background: "rgba(0, 0, 0, 0.08)", padding: 4, margin: 3, borderRadius: 2 }}>
                <Typography>{row.payload}</Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  } else if (event_type === 'outage') {
    return (
      <React.Fragment>
        <TableRow sx={{
          "& > *": { border: "none", ":hover": { cursor: "pointer" }, boxShadow: open ? "0px 4px 4px -2px rgba(0, 0, 0, 0.1)" : "none" }
        }} onClick={() => setOpen(!open)}>
          <TableCell sx={{ border: "none" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="center" sx={{ border: "none", fontWeight: typography.font_weight.mediumx }}>
            {moment.tz(row.start_time, DEFAULT_TIMEZONE).tz(timezone).format("YYYY-MM-DD hh:mm A z")}
          </TableCell>
          <TableCell align="left" sx={{ border: "none" }}>
            <Typography sx={{
              fontWeight: typography.font_weight.mediumx,
              fontSize: typography.font_size.base,
            }}>
              {(() => {
                if (row.description) {
                  return row.description;
                } else {
                  switch (row?.data?.current_status){
                    case "up":
                      if (row?.component_status_changes.length === 0) {
                        return "Services are now up and running";
                      } else {
                        let upComponents = row.component_status_changes.filter((component) => component.current_status === "up");
                        if (upComponents.length > 0) {
                          return `${row.component_status_changes.map((component) => component.name).join(", ")} Services are now up and running`;
                        }
                      }
                    case "down":
                      if (row?.component_status_changes.length === 0) {
                        return "Major outage detected in few services";
                      } else {
                        let downComponents = row.component_status_changes.filter((component) => component.current_status === "down");
                        if (downComponents.length > 0) {
                          return `${row.component_status_changes.map((component) => component.name).join(", ")} Services are experiencing major outage`;
                        }
                      }
                    case "maintenance":
                      if (row?.component_status_changes.length === 0) {
                        return "Maintenance in progress in few services";
                      } else {
                        let maintenanceComponents = row.component_status_changes.filter((component) => component.current_status === "maintenance");
                        if (maintenanceComponents.length > 0) {
                          return `${row.component_status_changes.map((component) => component.name).join(", ")} Services are under maintenance`;
                        }
                      }
                    case "warn":
                      if (row?.component_status_changes.length === 0) {
                        return "Minor outage detected in few services";
                      } else {
                        let warnComponents = row.component_status_changes.filter((component) => component.current_status === "warn");
                        if (warnComponents.length > 0) {
                          return `${row.component_status_changes.map((component) => component.name).join(", ")} Services are experiencing minor outage`;
                        }
                      }
                    default:
                      return "Unknown status";
                  }
                }
              })()}
            </Typography>
            <Typography sx={{
              fontSize: typography.font_size.sm,
              color: "rgba(99, 104, 124, 1)",
            }}>
              {row?.data?.details}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Table size="small" aria-label="components">
                  <TableHead>
                    <TableRow sx={{
                      backgroundColor: '#E4E4E4'
                    }}>
                      <TableCell align="center" sx={{ width: "35%" }}>Component Name</TableCell>
                      <TableCell align="center" sx={{ width: "40%" }}>Group</TableCell>
                      <TableCell align="center" sx={{ width: "25%" }}>Status Change</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.component_status_changes.map((component_status_change) => (
                      <TableRow key={component_status_change.group_name} sx={{
                        backgroundColor: '#F6F6F6',
                      }}>
                        <TableCell align="center">
                          {component_status_change.name}
                        </TableCell>
                        <TableCell align="center">
                          {component_status_change.group_name ? component_status_change.group_name : "-"}
                        </TableCell>
                        <TableCell align="center" sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                          <Box
                            sx={{
                              display: 'flex',
                              direction: 'row',
                              alignContent: 'center',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            <Chip
                              label={component_status_change.last_status}
                              variant="outlined"
                              color={component_status_change.last_status === "up" ? "success" : (component_status_change.last_status === "down" ? "error" : (component_status_change.last_status === "maintenance" ? "info" : "warning"))}
                              size="small"
                              sx={{
                                fontSize: typography.font_size.xs,
                                fontWeight: typography.font_weight.mediumx,
                                textTransform: "capitalize",
                              }}
                            />
                            <Icon component={EastIcon} sx={{
                              fontSize: typography.font_size.base,
                              color: 'rgba(99, 104, 124, 1)',
                              margin: '0 8px',
                            }} />
                            <Chip
                              label={component_status_change.current_status}
                              variant="outlined"
                              color={component_status_change.current_status === "up" ? "success" : (component_status_change.current_status === "down" ? "error" : (component_status_change.current_status === "maintenance" ? "info" : "warning"))}
                              size="small"
                              sx={{
                                fontSize: typography.font_size.xs,
                                fontWeight: typography.font_weight.mediumx,
                                textTransform: "capitalize",
                              }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
};

const EventPayloadTable = ({ events, type }) => {
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const uniqueColumnSet = new Set();
    if (isMounted) {
      // Iterate over the array and extract keys from each object
      events.forEach((event) => {
        Object.keys(event).forEach((key) => {
          uniqueColumnSet.add(key); // Add key to the Set
        });

        // Parse event.data if it exists
        if (event.data) {
          event.data = JSON.parse(event.data);
        }
      });
      if (type === 'error') {
        // Convert Set to array if needed
        setColumns([...uniqueColumnSet]);
      } else if (type === 'outage') {
        // Set the columns for the outage events: start_time, description, data.details
        setColumns(["start_time", "description"]);
      }
      setIsLoading(false);
    }
    return () => (isMounted = false);
  }, [setColumns, events]);

  if (isLoading) {
    return null;
  }

  if (type === 'error') {
    return (
      <TableContainer component={Paper} style={{ overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center" sx={{ width: "24%" }}>
                Occurred At
              </TableCell>
              <TableCell align="center" sx={{ width: "13%" }}>
                Status Code
              </TableCell>
              <TableCell align="center" sx={{ width: "63%" }}>
                Payload
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((row) => (
              <RenderRows key={row.event_uuid} row={row} event_type={type}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (type === 'outage') {
    return (
      <TableContainer component={Paper} style={{ maxHeight: 400, overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center" sx={{ width: "24%" }}>
                Outage Time
              </TableCell>
              <TableCell align="center" sx={{ width: "76%" }}>
                Details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((row) => (
              <RenderRows key={row.event_uuid} row={row} event_type={type} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default EventPayloadTable;
