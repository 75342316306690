import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { errorMessage } from "../shared/intl/error-messages";
import ScreenError from "./common/error-components/ScreenError";
import HomeLayout from "./HomeLayout";
import OnboardingLayout from "./OnboardingLayout";
import { useNavigate } from "react-router-dom";

const isDevelopment = process.env.NODE_ENV === "development";

const FallbackComponent = ({ error }) => {
  return (
    <ScreenError title="" message={errorMessage.UNEXPECTED_ERROR} showButtons>
      {isDevelopment ? (
        <Container maxWidth="md" mx="auto">
          <pre style={{ whiteSpace: "pre-wrap" }}>{error.message}</pre>
        </Container>
      ) : null}
    </ScreenError>
  );
};

// This is the master component just beneath App.jsx
// The main purpose of this layout is understand if onboarding of user is complete
// If so trigger onboarding flow else trigger
const AppLayout = () => {
  const [onboardingFlow, setOnboardingFlow] = useState(false);
  const navigate = useNavigate();
  useEffect(()=>{
    // We Will Add the Flow which navigates either to home page or Onboarding
    // In side Onboarding we will then handle the drop Off points and then navigate accordingly

    setOnboardingFlow(false); // Placeholder for navigate
  }, []);

  if (onboardingFlow) {
    return (
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <OnboardingLayout />
      </ErrorBoundary>
    );
  }
  return <HomeLayout />;
};

export default AppLayout;
