import axios from "axios";
import { GET_PROFILE_DETAILS_FAIL, GET_PROFILE_DETAILS_SUCCESS, GET_PROFILE_DETAIL_REQUEST } from "../constants/preferenceConstants";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS
} from "../constants/userConstants";

const url = process.env.REACT_APP_BASE_URL;

// verify if current user is loggedIn or not
export const getIsUserLoggedIn = () => async (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST });

  try {
    const { data } = await axios.get(`${url}auth/verify`);
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: { user: data.user, tenants: data.tenants },
    });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error.response;
  }
};

export const userLogin = (input) => (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: input,
    });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserProfileDetails = () => async( dispatch)=>{
  try{
    dispatch({ type: GET_PROFILE_DETAIL_REQUEST });

    const { data } = await axios.get(`${url}users/profile`);

    dispatch({ type: GET_PROFILE_DETAILS_SUCCESS, payload: data });

    return data;
  }catch(error){
    dispatch({
      type: GET_PROFILE_DETAILS_FAIL,
      payload: error.response,
    });
  }

};
